import React from "react"

import Layout from "src/components/layout"
import Head from "src/components/Head"

import AffiliatesUpdateTop from "../components/affiliatesUpdate/AffiliatesUpdateTop/AffiliatesUpdateTop"

import COUNTRY from "../constants"
import { graphql } from "gatsby"
import AffiliatesList
  from "../components/affiliatesUpdate/AffiliatesList/AffiliatesList"

const AffiliatesUpdateMain = ({ location, data, pageContext }) => {
  const attractions = pageContext.attractions

  return (
    <Layout
      country={COUNTRY.BY}
      hotLine={data.site.siteMetadata.countries.by.hotLine}
      location={location}
    >
      <Head
        lang={COUNTRY.BY}
        title="Обновления услуг AllSports"
        description="Обновления и изменения услуг AllSports"
        slug="/affiliates-update-2021-12-15/"
      />
      <AffiliatesUpdateTop type="affiliates" />
      <AffiliatesList affiliatesArray={attractions} />
    </Layout>
  )
}

export default AffiliatesUpdateMain

export const query = graphql`
  query AffiliatesUpdate {
    site {
      siteMetadata {
        senderPath
        countries {
          by {
            hotLine {
              tel
              title
            }
          }
        }
      }
    }
  }
`
